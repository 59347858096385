import { Box, Stack, Typography } from '@mui/material';
import type { Talent } from '@spec/Talent';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect } from 'react';
import { useAsyncState } from '../../../../lib/AsyncResource';
import { generateTouchLinkUrl } from '../../../../Routes';
import { FlexBox } from '../../../FlexBox';
import { HelpAccordion } from '../../../HelpAccordion';

export const TouchLink = (props: { talent: Talent }) => {
    const [maybeUrl, setMaybeUrl] = useAsyncState<string>({
        inProgress: true,
        value: null,
    });
    useEffect(() => {
        generateTouchLinkUrl(props.talent)
            .then((value) => setMaybeUrl({ inProgress: false, value }))
            .catch((e) => setMaybeUrl({ inProgress: false, value: e }));
    }, [props.talent, setMaybeUrl]);
    if (maybeUrl.value === null) {
        return null;
    }
    const url = maybeUrl.value;
    if (url instanceof Error) {
        return <>error</>;
    }
    return (
        <HelpAccordion title="HiTouchとヒトノワをリンクする">
            <FlexBox gap={2}>
                <Box
                    sx={{
                        border: '12px solid #fff',
                    }}
                >
                    <QRCodeCanvas value={url} size={80} />
                </Box>
                <Stack spacing={1}>
                    <Typography>
                        U-Nightに提供したスマホ向けのアプリケーション「HiTouch（はい、タッチ）」と連携する実験的な機能です。
                    </Typography>
                    <Typography>
                        このQRコードを、HiTouchにログインしたスマホで開いてください。HiTouchそのものの説明はまた後で書きます。
                    </Typography>
                    <Typography>
                        これはあなた専用のQRコードです。他の人に見せないようにしてください。
                    </Typography>
                </Stack>
            </FlexBox>
        </HelpAccordion>
    );
};
