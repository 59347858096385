import { Alert, Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import React from 'react';
import { RoutingPattern } from '../../../Routes';
import {
    fullName,
    isLeftTalent,
    isRestrictedTalent,
    isSuspendedTalent,
} from '../../../domains/Talent';
import { useMeContext } from '../../../queries/me';
import { LinkButton } from '../../ActionButtons';
import { useCurrentTimeContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { EditIcon } from '../../Icons';
import { MonthDuration } from '../../MonthDuration';
import { TalentAvatar } from '../../TalentAvatar';
import { TenantContent } from '../../TenantContent';
import { UnitText } from '../../UnitText';
import { EditMenu } from './EditMenu';
import { EmploymentHistory } from './EmploymentHistory';
import { LeaveOfAbsences } from './LeaveOfAbsences';
import { NotesArticles } from './NotesArticles';
import { PersonalEventHistory } from './PersonalEventHistory';
import { SabbaticalLeaveAlert } from './SabbaticalLeaveAlert';
import { SabbaticalLeaves } from './SabbaticalLeaves';
import { SetDefaultImageButton } from './SetDefaultImageButton';
import { TransferHistory } from './TransferHistory';
import { AbsentMenu } from './absent/AbsentMenu';
import { Carta } from './basicProfile/Carta';
import { Treasure2024 } from './basicProfile/Treasure2024';
import { GrantedPrivileges } from './grants/GrantedPrivileges';
import { SabbaticalLeaveBasedDate } from './sabbaticalLeave';
import { Touch } from './touch/Touch';

const LeftAlert: React.FC = () => {
    const { grants } = useMeContext();
    return grants.showLeavedTalent ? (
        <Alert severity="warning">
            退職済みの従業員です。あなたには退職者の閲覧権限が付与されているため、入退社・異動履歴や過去の経歴が見えています。
        </Alert>
    ) : (
        <Alert severity="warning">退職済みの従業員です</Alert>
    );
};

const SuspendedAlert: React.FC = () => {
    const { grants } = useMeContext();
    if (grants.showLeavedTalent === false) {
        throw Error('Show suspended talent without privilege');
    }
    return (
        <Alert severity="warning">
            停止済みのアカウントです。あなたには閲覧権限が付与されているため見えますが、一般アカウントには見えません。
        </Alert>
    );
};

const RestrictedAlert: React.FC = () => {
    return (
        <Alert severity="warning">このアカウントはヒトノワへのログインが許可されていません。</Alert>
    );
};

export const Profile: React.FC<{
    talent: Talent;
    teams: Team[];
}> = (props) => {
    const { me, grants } = useMeContext();
    const canShowLeavedTalent = grants.showLeavedTalent;
    const canShowPrivacyInfo = grants.showFullPersonality;
    const t = props.talent;
    const shouldHide = isLeftTalent(t) && !canShowLeavedTalent;
    const { currentTime } = useCurrentTimeContext();
    return (
        <Box>
            <Stack spacing={1}>
                {isLeftTalent(t) && <LeftAlert />}
                {isSuspendedTalent(t) && <SuspendedAlert />}
                {isRestrictedTalent(t) && <RestrictedAlert />}
            </Stack>
            <FlexBox my={2} flexWrap="nowrap" alignItems="flex-start">
                <Box mr={2}>
                    <TalentAvatar size="large" talent={t} />
                </Box>
                <Box mt={-0.5} flexGrow={1}>
                    <FlexBox alignItems="flex-end">
                        <Box>
                            <Typography variant="h6">{fullName(t)}</Typography>
                        </Box>
                        <Box flexGrow={1}>
                            <Typography variant="h6">
                                {t.nicknames !== '' ? (
                                    <>（{t.nicknames}）</>
                                ) : (
                                    <Box color="text.disabled">（ニックネーム設定なし）</Box>
                                )}
                            </Typography>
                        </Box>
                        {me.id === t.id && (
                            <Box mr={1} mb={0.5}>
                                <LinkButton
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    to={RoutingPattern.editMe}
                                >
                                    自分の情報を編集する
                                </LinkButton>
                            </Box>
                        )}
                    </FlexBox>
                    <Divider />
                    <TenantContent
                        carta={<Carta talent={t} teams={props.teams} />}
                        treasure2024={<Treasure2024 talent={t} teams={props.teams} />}
                    />
                </Box>
            </FlexBox>
            <GrantedPrivileges talent={t} />
            <TenantContent
                carta={
                    me?.id === t.id && (
                        <Stack mt={2} spacing={2}>
                            <SabbaticalLeaveAlert />
                            <Touch talent={t} />
                        </Stack>
                    )
                }
            />
            <NotesArticles talent={t} />
            <Paper
                sx={{
                    my: 2,
                    p: 2,
                }}
            >
                <Typography variant="h6">経歴</Typography>
                <FlexBox mt={1}>
                    <Box>
                        <Typography variant="body2">
                            入社日 : {dayjs(t.joinedAt).format('YYYY年M月D日')}
                            （<UnitText text="入社 " />
                            <MonthDuration now={currentTime} date={t.joinedAt} />）
                            {t.isNewGraduate && '（新卒）'}
                        </Typography>
                    </Box>
                    {isLeftTalent(t) && (
                        <Box ml={2}>
                            <Typography variant="body2">
                                退職日 : {dayjs(t.employment.leavedAt).format('YYYY年M月D日')}
                            </Typography>
                        </Box>
                    )}
                </FlexBox>
                {shouldHide ? (
                    <Typography mt={1} variant="body2" color="primary">
                        ※退職者の入退社・異動履歴の閲覧には「退職者閲覧」権限が必要です
                    </Typography>
                ) : (
                    <Box>
                        <EmploymentHistory talent={t} />
                        <TransferHistory talent={t} teams={props.teams} />
                        <PersonalEventHistory talent={t} />
                    </Box>
                )}
            </Paper>
            {canShowPrivacyInfo && (
                <Box mt={4}>
                    <Typography variant="h6">プライバシー情報</Typography>
                    <SabbaticalLeaves talent={t} />
                    <LeaveOfAbsences talent={t} />
                </Box>
            )}
            <EditMenu talent={t} />
            <SetDefaultImageButton talent={t} />
            <SabbaticalLeaveBasedDate talent={t} />
            <AbsentMenu talent={t} />
        </Box>
    );
};
