import { Box, Card, CardContent, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { useAuthorsNote } from '../../../queries/notes';
import { CardLink, LinkButton } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { AccessTimeIcon, FeedIcon, KeyboardDoubleArrowRightIcon, PushPinIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { WaitQuery } from '../../WaitLoading';
import { LikesCounter } from '../../notes/LikesCounter';
import { getArticleUrl, getAuthorUrl } from '../../notes/urls';

const DISPLAY_ARTICLES = 5;

export const NotesArticles: React.FC<{ talent: Talent }> = (props) => {
    const maybeArticles = useAuthorsNote(props.talent.hitonowaId);
    return (
        <WaitQuery query={maybeArticles}>
            {({ data }) => <ArticleList talent={props.talent} articles={data.articles} />}
        </WaitQuery>
    );
};

const ArticleList: React.FC<{
    talent: Talent;
    articles: NotesArticleSummary[];
}> = (props) => {
    if (props.articles.length === 0) {
        return null;
    }
    const pinnedArticles = props.articles.filter((v) => v.isPinned === true);
    const recentArticles = props.articles
        .filter((v) => v.isPinned === false)
        .slice(0, DISPLAY_ARTICLES);
    return (
        <Box mt={2}>
            <Box mb={1}>
                <SubTitle icon={FeedIcon} title="ヒトノワノート" />
            </Box>
            {pinnedArticles.length > 0 && (
                <>
                    <Typography variant="body2">ピン留めされた記事</Typography>
                    <Box mt={0.5} display="flex" gap={2}>
                        {pinnedArticles.map((v) => (
                            <ArticleCard key={v.id} talent={props.talent} article={v} />
                        ))}
                    </Box>
                </>
            )}
            {recentArticles.length > 0 && (
                <>
                    <Typography variant="body2">最近の記事</Typography>
                    <Box mt={0.5} display="flex" gap={2}>
                        {recentArticles.map((v) => (
                            <ArticleCard key={v.id} talent={props.talent} article={v} />
                        ))}
                    </Box>
                </>
            )}
            <Box mt={-0.5} mr={1}>
                <LinkButton
                    to={getAuthorUrl(props.talent.hitonowaId)}
                    startIcon={<KeyboardDoubleArrowRightIcon />}
                >
                    {props.talent.hitonowaId}さんの記事一覧を見る
                </LinkButton>
            </Box>
        </Box>
    );
};

const ArticleCard: React.FC<{ talent: Talent; article: NotesArticleSummary }> = (props) => {
    const v = props.article;
    const articleUrl = getArticleUrl(v.id, props.talent.hitonowaId);
    return (
        <Card
            square={false}
            sx={(theme) => ({
                flexGrow: 1,
                flexBasis: 0,
                maxWidth: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
                backgroundColor: theme.palette.background.default,
            })}
        >
            <CardLink to={articleUrl} sx={{ height: '100%' }}>
                <CardContent>
                    <FlexBox>
                        {v.isPinned === true && (
                            <PushPinIcon color="secondary" fontSize="small" sx={{ mr: 0.5 }} />
                        )}
                        <Typography
                            flexGrow={1}
                            color="link"
                            sx={{
                                // avoid nested anchor elements
                                '&:hover': { textDecoration: 'underline' },
                            }}
                        >
                            {v.title}
                        </Typography>
                    </FlexBox>
                    <FlexBox mt={0.5} gap={1}>
                        <Box flexGrow={1}>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                        </Box>
                        <LikesCounter article={props.article} />
                    </FlexBox>
                </CardContent>
            </CardLink>
        </Card>
    );
};
