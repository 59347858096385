import { Avatar, Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import {
    SurveyBooleanQuestion,
    SurveyLikertQuestion,
    SurveyQuestion,
    type SurveySelectQuestion,
} from '@spec/Survey';
import { isBooleanQuestion, isLikertQuestion, isSelectQuestion } from '../../../../domains/Survey';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { FlexBox } from '../../../FlexBox';
import { CheckBoxIcon, ChecklistIcon, LinearScaleIcon, SubjectIcon } from '../../../Icons';
import { NoItems } from '../../../NoItems';
import { useDialogContext, useEditQuestionContext } from './Context';

export const QuestionList: React.FC<{ questions: SurveyQuestion[] }> = (props) => {
    if (props.questions.length === 0) {
        return <NoItems my={4}>質問はまだありません</NoItems>;
    }
    return (
        <Box>
            {props.questions.map((v) => (
                <QuestionCard key={v.id} question={v} />
            ))}
        </Box>
    );
};

const QuestionCard: React.FC<{ question: SurveyQuestion }> = (props) => {
    const { setDialogMode } = useDialogContext();
    const { question } = useEditQuestionContext();
    const q = props.question;
    return (
        <Card>
            <CardContent>
                <FlexBox flexWrap="nowrap">
                    <Avatar variant="rounded">
                        <QuestionTypeIcon q={q} />
                    </Avatar>
                    <Box ml={1} flexGrow={1}>
                        <Typography>{q.shortTitle}</Typography>
                        <Typography variant="body2">{q.title}</Typography>
                    </Box>
                    <Box>
                        {isLikertQuestion(q) && <LikertQuestionRange question={q} />}
                        {isBooleanQuestion(q) && <BooleanQuestionLabel question={q} />}
                        {isSelectQuestion(q) && <SelectQuestionOptions question={q} />}
                    </Box>
                    <Box ml={2}>
                        <EditIconButton
                            onClick={() => {
                                question.setValue(q);
                                setDialogMode('EditQuestion');
                            }}
                        />
                        <DeleteIconButton
                            onClick={() => {
                                question.setValue(q);
                                setDialogMode('DeleteQuestion');
                            }}
                        />
                    </Box>
                </FlexBox>
            </CardContent>
        </Card>
    );
};

type IconFontSize = 'large' | 'medium' | 'small';

export const QuestionTypeIcon: React.FC<{ q: SurveyQuestion; fontSize?: IconFontSize }> = (
    props
) => {
    if (isLikertQuestion(props.q)) {
        return <LinearScaleIcon fontSize={props.fontSize} />;
    }
    if (isBooleanQuestion(props.q)) {
        return <CheckBoxIcon fontSize={props.fontSize} />;
    }
    if (isSelectQuestion(props.q)) {
        return <ChecklistIcon fontSize={props.fontSize} />;
    }
    return <SubjectIcon fontSize={props.fontSize} />;
};

const LikertQuestionRange: React.FC<{ question: SurveyLikertQuestion }> = (props) => {
    const q = props.question;
    const theme = useTheme();
    return (
        <Box ml={1}>
            <FlexBox gap={theme.spacing(0.5)}>
                <Typography variant="body2">{q.minLabel}</Typography>
                <Typography>{q.minScore}</Typography>
                <Typography variant="body2">〜</Typography>
                <Typography>{q.maxScore}</Typography>
                <Typography variant="body2">{q.maxLabel}</Typography>
            </FlexBox>
        </Box>
    );
};

const BooleanQuestionLabel: React.FC<{ question: SurveyBooleanQuestion }> = (props) => {
    const q = props.question;
    return (
        <Box ml={1}>
            <Typography variant="body2">{q.label}</Typography>
        </Box>
    );
};

const SelectQuestionOptions: React.FC<{ question: SurveySelectQuestion }> = (props) => {
    const q = props.question;
    return (
        <Box ml={1}>
            <FlexBox>
                <Typography ml={2} variant="body2">
                    選択肢
                </Typography>
                {q.options.map((v) => (
                    <Typography key={v} variant="body2">
                        「{v}」
                    </Typography>
                ))}
            </FlexBox>
        </Box>
    );
};
