import { Team, TeamId } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { ApplicationError } from '../Errors';
import { findById } from '../lib/ArrayUtils';

export const findBaseTeam = (talent: Talent, teams: Team[]): Team | null => {
    const team = findById(talent.teamId, teams);
    if (team.baseTeamId === team.id) {
        return null;
    }
    return findById(team.baseTeamId, teams);
};

export const getBaseTeamName = (talent: Talent, teams: Team[]): string => {
    const team = findBaseTeam(talent, teams) ?? findById(talent.teamId, teams);
    return team.name;
};

export const findChildTeams = (teamId: TeamId, teams: Team[]): Team[] => {
    const current = teams.filter((v) => v.id === teamId);
    const children = teams.map((v) => {
        if (v.parentId === teamId) {
            return findChildTeams(v.id, teams);
        }
        return [];
    });
    return [current, children.flat()].flat();
};

export const findParentTeams = (teamId: TeamId, teams: Team[]): Team[] => {
    let current = findById(teamId, teams);
    const parents: Team[] = [current];
    while (current.parentId !== null) {
        current = findById(current.parentId, teams);
        parents.push(current);
    }
    return parents;
};

// avoid infinite loop
const BREADCRUMBS_MAX_DEPTH = 20;

export const generateBreadcrumbs = (teamId: TeamId, teams: Team[]): Team[] => {
    const current = teams.find((v) => v.id === teamId);
    if (current === undefined) {
        throw Error(`current team ${teamId} not found`);
    }
    const nav: Team[] = [current];
    let i = BREADCRUMBS_MAX_DEPTH;
    while (i-- > 0) {
        if (nav[0].parentId === null) {
            break;
        }
        if (i === 0) {
            throw new Error('the stack reached depth limit');
        }
        const parent = teams.find((v) => v.id === nav[0].parentId);
        if (parent) {
            nav.unshift(parent);
        } else {
            throw new Error('parent not found');
        }
    }
    return nav;
};

export const getTeamNameTree = (teamId: TeamId, teams: Team[]): string[] => {
    const tree = generateBreadcrumbs(teamId, teams);
    return tree
        .slice(tree.length > 1 ? 1 : 0)
        .map((v) => v.name)
        .filter(
            (v, i, arr) => !(i > 0 && arr[i - 1].endsWith('ディビジョン') && v.endsWith('グループ'))
        );
};

export const isAvailableTeam = (team: Team): boolean => team.abolishedAt === null;

export const isAvailableTeamWithBasedOn = (team: Team, basedOn: Date): boolean => {
    if (team.abolishedAt === null) {
        return true;
    }
    if (basedOn === undefined) {
        return false;
    }
    return !dayjs(basedOn).isAfter(team.abolishedAt, 'day');
};

export const findTeam = (teamId: TeamId, teams: Team[]): Team => {
    const team = teams.find((v) => v.id === teamId);
    if (team === undefined) {
        throw new ApplicationError(`Team not found id: ${teamId}`);
    }
    return team;
};

export const getCorporate = (talent: Talent, teams: Team[]): Team => {
    let team = findById(talent.teamId, teams);
    while (team.parentId) {
        const parentTeam = findById(team.parentId, teams);
        if (parentTeam.isCorporate) {
            return parentTeam;
        }
        team = parentTeam;
    }
    // consider the root team as corporate
    return team;
};
