import { TeamId } from '@spec/Organization';
import type { Talent } from '@spec/Talent';
import { ValueFeedback } from '@spec/ValueFeedback';
import dayjs from 'dayjs';
import { toBase64Url } from './Crypto';

export const RoutingPattern = {
    index: '/',
    me: '/me',
    admin: '/admin',
    todo: '/todo',
    todoAdmin: '/todo/admin',
    talents: '/talents',
    registerTalent: '/register',
    talentDetail: '/talents/:employeeCode',
    editPersonality: '/talents/:employeeCode/edit/personality',
    setDefaultImage: '/talents/:employeeCode/edit/profile-image',
    editMe: '/talents/me/edit',
    iam: '/iam/:hitonowaId',
    workplaces: '/talents/workplaces',
    newcomers: '/talents/newcomers/:year/:month',
    organization: '/organization',
    teamDetail: '/organization/team/:teamId',
    valueFeedbacks: '/value-feedback',
    valueFeedbackStatus: '/value-feedback/:id/status',
    valueFeedbackGrants: '/value-feedback/grants',
    techAssessment: '/tech-assessment',
    survey: '/survey',
    surveyAdmin: '/survey/admin',
    circle: '/circle',
    job: '/job',
    diveAdmin: '/job/dive/admin',
    grants: '/grants',
    logs: '/logs',
    apiReference: '/api-reference',
    notes: '/notes',
};

export const getRelativePath = (basePath: string) => (path: string) => path.replace(basePath, '');

export const getTalentUrl = (employeeCode: string): string => `/talents/${employeeCode}`;

export const getIamUrl = (hitonowaId: string): string => `/iam/${hitonowaId}`;

export const getTalentEditPersonalityUrl = (employeeCode: string): string =>
    `/talents/${employeeCode}/edit/personality`;

export const getTalentSetDefaultImageUrl = (employeeCode: string): string =>
    `/talents/${employeeCode}/edit/profile-image`;

export const getTeamUrl = (teamId: TeamId): string => `/organization/team/${teamId}`;

export const getValueFeedbackUrl = (
    valueFeedback: ValueFeedback,
    mode?: 'admin' | 'status' | 'members' | 'manager'
): string => {
    const base = `/value-feedback/${valueFeedback.id}`;
    if (mode === undefined) {
        return base;
    }
    if (mode === 'status' && dayjs(valueFeedback.startSelectionAt).isAfter(dayjs('2024-01-01'))) {
        return `${base}/admin`;
    }
    return `${base}/${mode}`;
};

export const getNewcomersUrl = (year: number, month: number): string =>
    `/talents/newcomers/${year}/${month}`;

export const getGitHubUrl = (githubId: string | null) =>
    githubId === null ? '' : `https://github.com/${githubId}`;

export const HRBP_LIST_URL =
    'https://sites.google.com/cartahd.com/caruupe/%E3%83%9B%E3%83%BC%E3%83%A0/%E4%BA%BA%E4%BA%8B%E5%8A%B4%E5%8B%99/hr%E7%AA%93%E5%8F%A3%E6%8B%85%E5%BD%93%E8%80%85%E4%B8%80%E8%A6%A7#h.nk3jzx4963yn';

export const generateTouchLinkUrl = async (t: Talent) => {
    const code = await toBase64Url(`hitonowa_touch_link_hash_base${t.id}`);
    return `https://touch.hitonowa.work/link?hid=${t.hitonowaId}&code=${code}`;
};
