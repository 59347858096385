import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { useTouches } from '../../../../queries/talent';
import { WaitQuery } from '../../../WaitLoading';

export const TouchHistory = () => {
    const maybeTouches = useTouches();
    return (
        <Box>
            <WaitQuery query={maybeTouches}>
                {({ data }) => {
                    const touches = sortByKey(data, 'matchedAt', 'desc');
                    return (
                        <Box>
                            <Typography>これまでに{touches.length}人とタッチしました</Typography>
                            <Table sx={{ width: 'auto', mt: 1 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>所属</TableCell>
                                        <TableCell>氏名</TableCell>
                                        <TableCell>最新タッチ</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {touches.map((v) => {
                                        return (
                                            <TableRow key={v.matchedAt.toISOString()}>
                                                <TableCell>{v.profile.team}</TableCell>
                                                <TableCell>{v.profile.name}</TableCell>
                                                <TableCell>
                                                    {dayjs(v.matchedAt).format(
                                                        'YYYY/MM/DD HH:mm:ss'
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    );
                }}
            </WaitQuery>
        </Box>
    );
};
